import React from "react";
import Layout from "src/components/structure/layout";
import { MDXProvider } from "@mdx-js/react";
import { Link } from "gatsby";
import PageHeading from "src/components/page-heading";

const shortcodes = { Link, PageHeading };

const MdxTemplate = ({ pageContext, children }) => {
  return (
    <Layout
      pageTitle={pageContext.frontmatter.title}
      showTopNotice={pageContext.frontmatter.showTopNotice}
    >
      <MDXProvider components={shortcodes}>{children}</MDXProvider>
    </Layout>
  );
};

export default MdxTemplate;
