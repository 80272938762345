import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import styled from "styled-components";
import { device } from "./styled/sitewide";
import { SmallDivider } from "./styled/sitewide";

const images = {
  thought: (
    <StaticImage
      alt=""
      layout="fullWidth"
      objectPosition="50% 25%"
      src="../../static/images/thought-leader.jpg"
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        opacity: 0.4,
      }}
    />
  ),
  sketch: (
    <StaticImage
      alt=""
      layout="fullWidth"
      objectPosition="50% 85%"
      src="../../static/images/thoughts-notepad.jpg"
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        opacity: 0.4,
      }}
    />
  ),
  meeting: (
    <StaticImage
      alt=""
      layout="fullWidth"
      objectPosition="50% 50%"
      src="../../static/images/team-hands.jpg"
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        opacity: 0.4,
      }}
    />
  ),
  cloud: (
    <StaticImage
      alt=""
      layout="fullWidth"
      objectPosition="50% 50%"
      src="../../static/images/cloud-architecture.jpg"
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        opacity: 0.4,
      }}
    />
  ),
  workspace: (
    <StaticImage
      alt=""
      layout="fullWidth"
      objectPosition="50% 60%"
      src="../../static/images/workspace.jpg"
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        opacity: 0.4,
      }}
    />
  ),
  working: (
    <StaticImage
      alt=""
      layout="fullWidth"
      objectPosition="50% 30%"
      src="../../static/images/team-coders.jpg"
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        opacity: 0.4,
      }}
    />
  ),
  schedule: (
    <StaticImage
      alt=""
      layout="fullWidth"
      objectPosition="50% 40%"
      src="../../static/images/schedule.png"
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        opacity: 0.2,
      }}
    />
  ),
  theorangepeel: (
    <StaticImage
      alt=""
      layout="fullWidth"
      objectPosition="50% 40%"
      src="../../static/images/the-orange-peel.png"
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        opacity: 0.6,
      }}
    />
  ),
};

const Header = styled.div`
  text-align: center;
  color: var(--text-over-primary);
  margin-top: 2rem;
  margin-bottom: 2rem;
  @media ${device.tablet} {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  h1,
  p {
    margin: 0;
  }
  h1 {
    font-weight: 500;
  }
  p {
    font-size: 0.9rem;
    font-weight: 300;
  }
  span {
    &::before {
      color: var(--primary);
      content: "»";
      margin: 0 0.5rem;
      font-size: 1.2rem;
    }
    &:first-child::before {
      content: "";
      margin: 0;
    }
  }
`;

const Divider = styled(SmallDivider)`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
`;

const PageHeading = ({ title, image, crumbs }) => {
  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        backgroundColor: "black",
        marginBottom: "2rem",
      }}
    >
      {images[image]}
      <div
        style={{
          position: "relative",
        }}
      >
        <Header>
          <div className="container">
            <h1 style={{ fontWeight: 600 }}>{title}</h1>
            <Divider></Divider>
            <p>
              {crumbs.map((c, i) => (
                <span key={i}>{c}</span>
              ))}
            </p>
          </div>
        </Header>
      </div>
    </div>
  );
};

export default PageHeading;
